<template>
  <el-dialog
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded reconciliation-modal mt-5 mb-0"
    @close="$emit('close')"
  >
    <template #title>
      <div class="px-5 py-4 rounded-top" @click="onClickOutside">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-start gap-2">
            <div style="margin-top: 3px">
              <p
                v-if="statusOverride"
                class="main-status-circle"
                :class="{
                  [`border border-4  border-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true,
                }"
              ></p>
              <div
                v-else-if="periodComplete !== RECONCILIATION_STATUSES.APPROVED"
                class="before-payment-due-circle d-flex align-items-center justify-content-center"
              >
                <HourglassIcon />
              </div>
              <div v-else-if="mainStatus === RECONCILIATION_STATUSES.APPROVED" class="approved">
                <ApprovedIcon class="mx-1" />
              </div>
              <p
                v-else
                class="main-status-circle"
                :class="{
                  [`bg-${RECONCILIATION_STATUS_COLOR[mainStatus]}`]: true,
                  ['border border-4 border-grey']: reconciliationLoading,
                }"
              >
                {{ reconciliationLoading ? '' : problemCount }}
              </p>
            </div>
            <div>
              <div class="d-flex gap-2">
                <el-dropdown trigger="click" @command="handleReconciliationSelect">
                  <span class="el-dropdown-link">
                    <h2 class="reconciliation-title">
                      {{
                        $t('reconciliationModal.reconciliation', {
                          date: formatDateShort(dateRange.toDate, isDaily),
                        })
                      }}
                    </h2>

                    <ChevronIcon />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="reconciliation-select">
                    <el-dropdown-item
                      v-for="reconciliationOption in reconciliationOptions"
                      :key="reconciliationOption.id"
                      :command="reconciliationOption.id"
                      :disabled="reconciliationOption.disabled"
                    >
                      {{ reconciliationOption.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div v-if="!reconciliationLoading" class="d-flex flex-row align-items-center">
                <Button
                  type="link"
                  class="text-typography-primary"
                  @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: reconciliation.businessId } })"
                >
                  {{ reconciliation.customer.name }}
                </Button>
                <ExchangeIcon class="mx-1" />
                <Button
                  type="link"
                  class="text-typography-primary"
                  @click.stop="
                    openContactPopover({
                      type: 'supplier',
                      payload: { worksAt: supplier.id, businessId: reconciliation.businessId },
                    })
                  "
                >
                  {{ supplier.name }}
                </Button>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-baseline">
            <Button
              v-if="!actionsLoading && channelUnreadMessagesCount[reconciliation.id] >= 0"
              class="position-relative p-1"
              type="icon"
              @click.stop="handleChatOpen()"
            >
              <ChatIcon />
              <span
                v-if="channelUnreadMessagesCount[reconciliation.id] > 0"
                class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
                :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
              >
                {{
                  channelUnreadMessagesCount[reconciliation.id] > 99
                    ? '+99'
                    : channelUnreadMessagesCount[reconciliation.id]
                }}
              </span>
            </Button>
            <el-dropdown
              v-if="!actionsLoading && !reconciliationLoading"
              class="d-flex justify-content-center"
              trigger="click"
              placement="bottom"
              @command="(command) => handleAction(command)"
            >
              <Button type="icon" class="action-btn p-0 mx-1">
                <KebabIcon />
              </Button>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-if="isAdmin"
                  :disabled="!mainStatus || mainStatus.status === RECONCILIATION_STATUSES.APPROVED"
                  :command="ACTIONS.OPEN_ISSUES_MODAL"
                >
                  <div class="d-flex align-items-center gap-2">
                    <AccountingIcon />
                    {{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="isAdmin && hasReconciliationManage" :command="ACTIONS.OPEN_CREDIT_MATCH_MODAL">
                  <div class="d-flex align-items-center gap-2">
                    <DocumentMatchingIcon />
                    {{ $t('billing.billingManagement.billingTable.actions.openCreditMatchModalData') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isAdmin && hasReconciliationManage"
                  divided
                  :command="ACTIONS.OPEN_RECONCILIATION_SETTINGS"
                >
                  <div class="d-flex align-items-center gap-2">
                    <SettingsIcon />
                    {{ $t('billing.billingManagement.billingTable.actions.singleReconciliationSettings') }}
                    <Tag v-if="!isSupplierTemplateDefined">
                      {{ $t('billing.billingManagement.billingTable.actions.default') }}
                    </Tag>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isAdmin && hasReconciliationManage"
                  :command="ACTIONS.BALANCE_UPDATE"
                  :disabled="!!balancePayment"
                >
                  <div class="d-flex align-items-center gap-2">
                    <SettingsIcon />
                    <p>{{ $t('payment.paymentTable.singleRowActions.balanceUpdate') }}</p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isAdmin && hasReconciliationManage"
                  divided
                  :disabled="!reconciliation.balanceAlignment.required || reconciliation.balanceAlignment.validated"
                  :command="ACTIONS.VALIDATE_BALANCE_ALIGNMENT"
                >
                  <div class="d-flex align-items-center gap-2">
                    <CheckCircleIcon />
                    {{ $t('billing.billingManagement.billingTable.actions.validateBalanceAlignment') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="isAdmin && hasReconciliationManage"
                  :disabled="
                    reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed
                  "
                  :command="ACTIONS.CLOSE_RECONCILIATION"
                >
                  <div
                    class="d-flex align-items-center gap-2"
                    :class="`${
                      reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed
                        ? 'text-disabled'
                        : 'text-typography-primary'
                    }`"
                  >
                    <ChequeredFlagIcon />
                    <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
                  <div class="d-flex align-items-center gap-2">
                    <ChatIcon />
                    {{ $t('billing.billingManagement.billingTable.actions.openChat') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <Button type="icon" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </div>
        </div>
      </div>
    </template>
    <el-popover v-if="contactPopover" :value="contactPopover" trigger="manual" placement="bottom">
      <ContactCard :user="contactUser" :customer="reconciliation.customer" :supplier="supplier" :token="token" />
    </el-popover>

    <div
      v-loading="reconciliationLoading || refetchLoading"
      class="h-100 overflow-auto border-top modal-body-content"
      @click="onClickOutside"
    >
      <div class="p-5 pt-4 h-100">
        <div class="d-flex justify-content-between">
          <ReconciliationInfoCard
            v-if="reconciliation.customer"
            class="mb-5 cards-alignment"
            :reconciliation="reconciliation"
            :reconciliation-refetch="reconciliationRefetch"
            :supplier="supplier"
            :customer="reconciliation.customer"
            @update="() => $emit('update')"
          />
          <StatusReflectionCard
            class="mb-5 cards-alignment status-reflection-card"
            :class="$direction === 'rtl' ? 'me-4' : 'ms-4'"
            :status-reflection="statusReflection"
            :business-id="reconciliation.businessId"
            :supplier="supplier"
            :reconciliation="reconciliation"
            :reconciliation-id="reconciliation.id"
            :is-admin="isAdmin"
            :tasks="reconciliationTasks"
            @update-task="handleTaskUpdate"
          />
        </div>
        <div class="py-5 d-flex" :class="$direction === 'rtl' ? 'pe-3' : 'ps-3'">
          <div class="d-flex align-items-stretch">
            <div class="stepper"></div>
          </div>
          <div class="w-100">
            <div class="d-flex align-items-baseline gap-4">
              <div class="position-relative pb-2" :class="$direction === 'rtl' ? 'ms-1' : 'me-1'">
                <span
                  class="top-50 start-50 translate-middle position-absolute rounded-circle center border border-4"
                  :class="{
                    [`bg-${RECONCILIATION_STATUS_COLOR[snapshotStatus]}`]: true,
                    'border-grey': reconciliationLoading,
                    'border-white': !reconciliationLoading,
                  }"
                  style="padding: 5px"
                />
              </div>
              <div class="d-flex">
                <h3 class="mb-5">{{ $t('reconciliationModal.snapshotAlignment') }}</h3>
                <el-tooltip placement="top" effect="dark">
                  <div slot="content">
                    <p>{{ $t('reconciliationModal.tooltips.snapshotAlignmentFirstRow') }}</p>
                    <p>{{ $t('reconciliationModal.tooltips.snapshotAlignmentSecondRow') }}</p>
                  </div>
                  <QuestionMarkIcon class="m-1" width="12" height="12" />
                </el-tooltip>
              </div>
            </div>
            <div id="balanceAlignmentStatus" class="d-flex align-items-top mb-2 gap-4">
              <div
                class="position-relative pb-2"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                :style="{ height: getHeight('balanceAlignmentStatus') }"
              >
                <span
                  class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                  :class="{
                    [`bg-${RECONCILIATION_STATUS_COLOR[balanceAlignmentStatus]}`]: true,
                    'border-grey': reconciliationLoading,
                    'border-white': !reconciliationLoading,
                  }"
                />
              </div>
              <div :id="`card-${balanceAlignmentCardId}`" class="card w-100">
                <div
                  class="header"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${balanceAlignmentCardId}`"
                  aria-expanded="false"
                  :aria-controls="balanceAlignmentCardId"
                >
                  <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                    <h4 class="align-self-center">
                      {{ $t('reconciliationModal.balanceAlignment') }}
                    </h4>
                  </div>
                </div>
                <div v-if="!reconciliationLoading" :id="balanceAlignmentCardId" class="collapse">
                  <ReconciliationDocuments
                    :balance-payment="balancePayment"
                    :balance-alignment="reconciliation.balanceAlignment"
                    :reconciliation-document-status="balanceAlignmentStatus"
                    :date-range="dateRange"
                    :documents="reconciliationDocuments"
                    :loading="reconciliationDocumentsLoading"
                    @open-document="handleOpenDocument"
                  />
                </div>
              </div>
            </div>
            <div id="paymentDifferencesStatus" class="d-flex align-items-top mb-2 gap-4">
              <div
                class="position-relative pb-2"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                :style="{ height: getHeight('paymentDifferencesStatus') }"
              >
                <span
                  class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                  :class="{
                    [`bg-${RECONCILIATION_STATUS_COLOR[paymentDifferenceStatus]}`]: true,
                    'border-grey': reconciliationLoading,
                    'border-white': !reconciliationLoading,
                  }"
                />
              </div>
              <div :id="`card-${paymentDifferencesCardId}`" class="card w-100">
                <div
                  class="header"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${paymentDifferencesCardId}`"
                  aria-expanded="false"
                  :aria-controls="paymentDifferencesCardId"
                >
                  <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                    <h4 class="align-self-center">
                      {{ $t('reconciliationModal.paymentDifferences') }}
                    </h4>
                  </div>
                </div>
                <div :id="paymentDifferencesCardId" class="collapse">
                  <PaymentDifferences
                    v-if="reconciliation.customer"
                    :payment-differences="customerPaymentDifferences"
                    :business="reconciliation.customer"
                    @match-payment-diffs="openPaymentDifferencesMatchingModal = true"
                    @open-document="handleOpenDocument"
                  />
                </div>
              </div>
            </div>
            <div id="creditNotesStatus" class="d-flex align-items-top mb-2 gap-4">
              <div
                class="position-relative pb-2"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                :style="{ height: getHeight('creditNotesStatus') }"
              >
                <span
                  class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                  :class="{
                    [`bg-${RECONCILIATION_STATUS_COLOR[creditNotesStatus]}`]: true,
                    'border-grey': reconciliationLoading,
                    'border-white': !reconciliationLoading,
                  }"
                />
              </div>
              <div :id="`card-${creditNotesToMatchCardId}`" class="card w-100">
                <div
                  class="header"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#${creditNotesToMatchCardId}`"
                  aria-expanded="false"
                  :aria-controls="creditNotesToMatchCardId"
                >
                  <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                    <h4 class="align-self-center">
                      {{ $t('reconciliationModal.creditNotesToMatch') }}
                    </h4>
                    <span class="credit-circle">
                      {{ creditNotesToMatch.length }}
                    </span>
                  </div>
                </div>
                <div :id="creditNotesToMatchCardId" class="collapse">
                  <CreditNotes
                    :credit-notes="creditNotesToMatch"
                    @match-credits="handleOpenMatchCredits"
                    @open-event-map="handleOpenEventMap"
                    @open-document="handleOpenDocument"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-5 d-flex" :class="$direction === 'rtl' ? 'pe-3' : 'ps-3'">
          <div class="d-flex align-items-stretch">
            <div class="stepper"></div>
          </div>
          <div class="w-100">
            <div class="d-flex align-items-baseline gap-4">
              <div class="position-relative pb-2" :class="$direction === 'rtl' ? 'ms-1' : 'me-1'">
                <span
                  class="top-50 start-50 translate-middle position-absolute rounded-circle center border border-4"
                  :class="{
                    [`bg-${RECONCILIATION_STATUS_COLOR[billingVerificationStatus]}`]: true,
                    'border-grey': reconciliationLoading,
                    'border-white': !reconciliationLoading,
                  }"
                  style="padding: 5px"
                />
              </div>
              <div class="d-flex">
                <h3 class="mb-5">{{ $t('reconciliationModal.billingVerification') }}</h3>
                <el-tooltip placement="top" effect="dark">
                  <div slot="content">
                    <p>{{ $t('reconciliationModal.tooltips.billingVerificationFirstRow') }}</p>
                    <p>{{ $t('reconciliationModal.tooltips.billingVerificationSecondRow') }}</p>
                  </div>
                  <QuestionMarkIcon class="m-1" width="12" height="12" />
                </el-tooltip>
              </div>
            </div>
            <div class="d-flex px-3 py-4 fw-bold" :class="$direction === 'rtl' ? 'me-5' : 'ms-5'">
              <div style="width: 250px">{{ $t('reconciliationModal.billingTable.reference') }}</div>
              <div style="width: 140px">{{ $t('commons.date') }}</div>
              <div style="width: 140px">{{ $t('commons.total') }}</div>
              <div class="flex-fill">{{ $t('reconciliationModal.billingStatus.reconciliationStatus') }}</div>
              <div style="width: 165px">{{ $t('reconciliationModal.billingStatus.paymentStatus') }}</div>
              <div style="width: 32px"></div>
            </div>
            <div
              v-for="(billing, index) in reconciliation.billings"
              :id="`status-${billing.id}`"
              :key="index"
              class="d-flex mb-2"
            >
              <ReconciliationBillingRow
                :index="index"
                :billing="billing"
                :loading="reconciliationLoading"
                :delivery-tasks-ids="deliveryTasksIds"
                :billings="reconciliation.billings"
                :reconciliation-month="reconciliationMonth"
                :supplier="supplier"
                :business="reconciliation.customer"
                @handleUnbilledOrdersTableRefetch="handleUnbilledOrdersTableRefetch"
                @handleBillingTableRefetch="handleBillingTableRefetch"
                @handlePaymentLinkClicked="handlePaymentLinkClicked"
                @handleOpenDocument="handleOpenDocument"
                @patchMissingEvent="patchMissingEvent"
                @handleOpenEventMap="handleOpenEventMap"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="handleEventMapModalClose" />
    <CreditMatchModal
      v-if="creditMatchModalData"
      :selected-billing-id="creditMatchModalData.billingId"
      :default-month="creditMatchModalData.month"
      :supplier="supplier"
      :business-id="reconciliation.businessId"
      @close="creditMatchModalData = null"
    />
    <PaymentDifferencesMatchingModal
      v-if="openPaymentDifferencesMatchingModal"
      :supplier="supplier"
      :payment-differences="supplierPayments"
      @close="openPaymentDifferencesMatchingModal = false"
      @paymentDifferenceMatchSuccess="handlePaymentsMatchSuccess"
    />
    <IssuesModal
      v-if="issueModalData"
      :month="issueModalData && issueModalData.month"
      :supplier-id="issueModalData && issueModalData.supplierId"
      :billing-id="issueModalData && issueModalData.billingId"
      :business-id="reconciliation.businessId"
      @close="issueModalData = null"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="reconciliation.customer"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
    <BalancePaymentModal
      v-if="balancePaymentModalSupplier"
      :supplier="balancePaymentModalSupplier"
      :business-id="reconciliation.customer.id"
      @close="balancePaymentModalSupplier = null"
      @balance-payment-create="refetchData"
    />
    <BalanceAlignmentModal
      v-if="balanceAlignmentReconciliation"
      :reconciliation="balanceAlignmentReconciliation"
      @close="balanceAlignmentReconciliation = null"
      @validate="validateBalanceAlignment"
    />
  </el-dialog>
</template>

<script>
import { ref, computed, onBeforeUnmount, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil, uniq, flatten, uniqBy, prop, pluck, isEmpty, equals, all, pipe, head } from 'ramda';
import Big from 'big.js';

import { Button, Tag } from '@/modules/core';
import {
  CloseIcon,
  QuestionMarkIcon,
  ApprovedIcon,
  ExchangeIcon,
  HourglassIcon,
  ChatIcon,
  ChequeredFlagIcon,
  CheckCircleIcon,
  SettingsIcon,
  DocumentMatchingIcon,
  AccountingIcon,
  KebabIcon,
  ChevronIcon,
} from '@/assets/icons';
import { useTenancy, useUser } from '@/modules/auth';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { CONSOLIDATED_DOCS, INVOICE_DOCS, DOCUMENT_TYPES } from '@/modules/document/types';
import { useSupplier } from '@/modules/suppliers';
import { DocumentModal } from '@/modules/documentModal';
import { useBalancePayments, formatBilledDate } from '@/modules/payment';
import { usePaymentDelete } from '@/modules/eventMapModal/compositions/payment';
import { ContactCard } from '@/modules/contact';
import { usePatchMissingEvent, useChannel } from '@/modules/reconciliation';
import { useTasks, usePatchTask, useActivateTask } from '@/modules/tasks';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useUncertainBillings } from '@/modules/billing';
import { useGlobalPermissions } from '@/modules/permissions';

import {
  RECONCILIATION_STATUSES,
  RECONCILIATION_STATUS_COLOR,
  RECONCILIATION_TAG_COLOR,
  DOCUMENT_STATUSES,
  DOCUMENT_STATUS_COLOR,
  BILLING_TYPE,
  BILLING_PAYMENT_STATE,
  INNER_TABLE_ACTIONS,
} from '../';
import {
  useBillings,
  useReconciliationsSummary,
  useEventDifferences,
  useDocuments,
  useReconciliationDocuments,
} from './compositions';
import {
  useReconciliation,
  useMissingEvents,
  useReconciliationTemplate,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  useCloseReconciliation,
  useUpdateReconciliation,
} from '../compositions';
import { StatusReflectionCard, ReconciliationInfoCard, BalanceAlignmentModal } from '../components';
import {
  getBillingImbalanceAmount,
  transformDifferences,
  createCreditRow,
  getBillingStatus,
  getMatchedAmount,
  getOtherReconciliationMatchedAmount,
  getHeight,
} from './utils';

import { CreditNotes, PaymentDifferences, ReconciliationDocuments, ReconciliationBillingRow } from './components';
import { ReconciliationSettingsModal } from '../reconciliationSettingsModal';

const ACTIONS = {
  OPEN_ISSUES_MODAL: 'issuesModal',
  OPEN_CREDIT_MATCH_MODAL: 'creditMatchModal',
  OPEN_RECONCILIATION_SETTINGS: 'openReconciliationSettings',
  BALANCE_UPDATE: 'balanceUpdate',
  VALIDATE_BALANCE_ALIGNMENT: 'validateBalanceAlignment',
  CLOSE_RECONCILIATION: 'closeReconciliation',
  OPEN_CHAT: 'openChat',
};

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    Button,
    CloseIcon,
    Tag,
    DocumentModal,
    QuestionMarkIcon,
    ApprovedIcon,
    ExchangeIcon,
    HourglassIcon,
    ChatIcon,
    ChequeredFlagIcon,
    CheckCircleIcon,
    SettingsIcon,
    DocumentMatchingIcon,
    AccountingIcon,
    KebabIcon,
    ChevronIcon,
    CreditNotes,
    PaymentDifferences,
    ReconciliationDocuments,
    ContactCard,
    ReconciliationSettingsModal,
    StatusReflectionCard,
    ReconciliationInfoCard,
    ReconciliationBillingRow,
    BalanceAlignmentModal,
    BalancePaymentModal: () => import('@/modules/payment/customer/components/BalancePaymentModal'),
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    CreditMatchModal: () => import('@/modules/activity/components/creditMatchModal/CreditMatchModal'),
    IssuesModal: () => import('@/modules/modals/issuesModal/IssuesModal'),
    PaymentDifferencesMatchingModal: () =>
      import('@/modules/payment/customer/components/PaymentDifferencesMatchingModal'),
  },
  props: {
    reconciliationId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const selectedReconciliationId = ref(props.reconciliationId);
    const { token } = useTenancy();
    const { isAdmin } = useUser();

    const { hasReconciliationManage } = useGlobalPermissions();

    const {
      reconciliation,
      loading: reconciliationLoading,
      refetch: reconciliationRefetch,
    } = useReconciliation(computed(() => selectedReconciliationId.value));

    const { supplier } = useSupplier(computed(() => reconciliation.value?.supplierId));

    const dateRange = computed(() => ({
      fromDate: reconciliation.value
        ? DateTime.fromISO(reconciliation.value.periodStart, { zone: 'UTC' }).toJSDate()
        : null,
      toDate: reconciliation.value
        ? DateTime.fromISO(reconciliation.value.periodEnd, { zone: 'UTC' }).endOf('day').toJSDate()
        : null,
    }));

    const isDaily = computed(() =>
      reconciliation.value ? reconciliation.value.periodStart === reconciliation.value.periodEnd : null
    );

    const { eventDifferences, refetch: eventDifferencesRefetch } = useEventDifferences(
      computed(() => ({
        customerId: !reconciliationLoading.value && reconciliation.value?.businessId,
        fromDate: reconciliation.value?.periodStart,
        toDate: reconciliation.value?.periodEnd,
      }))
    );

    const documentDateRange = computed(() => {
      let minDate;
      let maxDate;
      [
        ...(reconciliation.value?.billings ?? []),
        ...(reconciliation.value?.orders ?? []),
        ...(eventDifferences.value?.orderDifferences ?? []),
      ].forEach((event) => {
        const date = new Date(event.date);
        if (!minDate) minDate = date;
        if (!maxDate) maxDate = date;
        if (date < minDate) minDate = date;
        if (date > maxDate) maxDate = date;
      });

      return {
        fromDate: DateTime.fromJSDate(minDate).toISODate(),
        toDate: DateTime.fromJSDate(maxDate).toISODate(),
      };
    });

    const { documents } = useDocuments(
      computed(() => ({
        businessId: reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
        fromDate: documentDateRange.value.fromDate ?? null,
        toDate: documentDateRange.value.toDate ?? null,
      }))
    );

    const { billings: credits } = useBillings(
      computed(() =>
        reject(isNil)({
          businessId: reconciliation.value?.businessId,
          supplierId: reconciliation.value?.supplierId,
          from: DateTime.fromJSDate(dateRange.value.fromDate).toISODate(),
        })
      )
    );

    const { balancePayments, refetch: balancePaymentsRefetch } = useBalancePayments(
      computed(() => ({
        businessId: reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
      })),
      () => ({
        enabled: !!reconciliation.value?.businessId && !!reconciliation.value?.supplierId,
      })
    );

    const { missingEvents, refetch: refetchMissingEvents } = useMissingEvents(
      () => ({
        businessId: reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
        estimatedDateFrom: DateTime.fromISO(reconciliation.value?.periodStart).toISODate(),
        estimatedDateTo: DateTime.fromISO(reconciliation.value?.periodEnd).toISODate(),
      }),
      () => ({
        enabled:
          !!reconciliation.value?.businessId &&
          !!reconciliation.value?.supplierId &&
          !!reconciliation.value?.periodStart &&
          !!reconciliation.value?.periodEnd,
      })
    );

    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() => {
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'));
      refetchMissingEvents();
      reconciliationRefetch();
    });
    patchMissingEventOnError(() =>
      $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'))
    );

    const { reconciliationDocuments, loading: reconciliationDocumentsLoading } = useReconciliationDocuments(
      computed(() => ({
        businessId: reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
        fromDate: DateTime.fromJSDate(dateRange.value.fromDate).minus({ years: 1 }).minus({ days: 1 }).toISODate(),
      }))
    );

    const docIds = computed(() => {
      const missingEventDocs = missingEvents.value
        .filter(({ parentDocumentId }) => parentDocumentId)
        .map(({ parentDocumentId }) => parentDocumentId);

      const paymentDifferencesDocs = flatten(
        eventDifferences.value.paymentDifferences.map(({ supplierPayment }) =>
          supplierPayment?.eventReferences.map(({ documentId }) => documentId)
        )
      );
      return uniq([...missingEventDocs, ...paymentDifferencesDocs]);
    });

    const { documents: rehandleParentDocuments } = useDocumentsByIds(
      computed(() => ({
        businessId: reconciliation.value?.businessId,
        ids: reject(isNil)(docIds.value),
      }))
    );

    const missingEventsWithDoc = computed(() =>
      missingEvents.value.map((missingEvent) => ({
        ...missingEvent,
        parentDocument: rehandleParentDocuments.value?.find(
          (document) => document.id === missingEvent.parentDocumentId
        ),
      }))
    );

    const missingBillings = computed(() =>
      missingEventsWithDoc.value
        .filter(({ possibleTypes }) =>
          possibleTypes.some((type) =>
            [...CONSOLIDATED_DOCS, ...INVOICE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
          )
        )
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.estimatedDate,
          source: {
            document: {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
              parentDocument: missingEvent.parentDocument,
            },
          },
          diffs: [],
          imbalances: [],
          deliveryRefs: [],
          orderLinks: [],
          related: [],
          parentDocument: missingEvent.parentDocument,
          parentDocumentId: missingEvent.parentDocumentId,
        }))
    );

    const orderIds = computed(() => reconciliation.value?.orders.map(({ id }) => id));

    const setDocumentsFromEventReferences = (event) => {
      const eventDocRef = event.eventReferences ? event.eventReferences[0].documentId : event.source?.ref;
      const doc = documents.value?.find(({ id }) => eventDocRef === id);
      if (doc) {
        const document = { id: doc.id, type: doc.type, documentNumber: doc.documentNumber };
        return event.eventReferences
          ? { ...event, eventReferences: [{ ...event.eventReferences[0], document }] }
          : { ...event, source: { ...event.source, document } };
      }
      return event;
    };

    const setDocumentsForPaymentDifference = (paymentDifference) => ({
      ...paymentDifference,
      supplierPayment: {
        ...paymentDifference.supplierPayment,
        eventReferences: paymentDifference.supplierPayment.eventReferences.map((eventReference) => ({
          ...eventReference,
          document: rehandleParentDocuments.value.find((doc) => doc.id === eventReference.documentId),
        })),
      },
    });

    const customerPaymentDifferences = computed(() =>
      eventDifferences.value.paymentDifferences
        .filter(
          ({ customerPayment, supplierPayment }) =>
            !customerPayment && supplierPayment.supplierId === reconciliation.value?.supplierId
        )
        .map(setDocumentsForPaymentDifference)
    );

    const orderDifferences = computed(() =>
      eventDifferences.value.orderDifferences
        .filter(({ supplierId }) => reconciliation.value?.supplierId === supplierId)
        .map((order) => ({
          ...order,
          toValidate: true,
          delivery: eventDifferences.value.deliveryDifferences.find(
            (delivery) => delivery.eventReferences[0].documentId === order.eventReferences[0].documentId
          ),
        }))
        .map(setDocumentsFromEventReferences)
    );

    const unbilledOrdersGroup = computed(() => {
      const { dateRangeOfUnbilled, orderLinks } = (reconciliation.value?.unbilledOrders ?? []).reduce(
        (prev, { date, orderId }) => {
          const millis = DateTime.fromISO(date, { zone: 'UTC' }).toMillis();
          prev.dateRangeOfUnbilled.minDate = Math.min(prev.dateRangeOfUnbilled.minDate, millis);
          prev.dateRangeOfUnbilled.maxDate = Math.max(prev.dateRangeOfUnbilled.maxDate, millis);
          prev.orderLinks.push({ order: { id: orderId } });
          return prev;
        },
        { dateRangeOfUnbilled: { minDate: Number.MAX_VALUE, maxDate: Number.MIN_VALUE }, orderLinks: [] }
      );

      const dateRangeOfCombined = (orderDifferences.value ?? []).reduce((prev, { date }) => {
        const millis = DateTime.fromISO(date, { zone: 'UTC' }).toMillis();
        prev.minDate = Math.min(prev.minDate, millis);
        prev.maxDate = Math.max(prev.maxDate, millis);
        return prev;
      }, { ...dateRangeOfUnbilled } ?? { minDate: Number.MAX_VALUE, maxDate: Number.MIN_VALUE });

      const deliveryRefs = [];
      [...orderLinks].forEach(({ order }) =>
        reconciliation.value?.deliveries.find((delivery) => {
          const foundDelivery = delivery.orderIds.includes(order?.id);
          if (foundDelivery) {
            deliveryRefs.push({ delivery });
          }
        })
      );

      return orderLinks?.length || orderDifferences.value?.length
        ? {
            minDate: dateRangeOfCombined.minDate,
            maxDate: dateRangeOfCombined.maxDate,
            orderLinks: orderLinks ?? [],
            diffs: [],
            imbalances: [],
            deliveryRefs,
            related: [],
            source: {},
            type: BILLING_TYPE.UNBILLED_ORDER,
            orderDifferences: orderDifferences.value,
            supplierId: reconciliation.value?.supplierId,
          }
        : null;
    });

    const {
      deliveryTasks,
      handleReconciliationTasks,
      supplierCreationTasks,
      uncertainBillingTasks,
      balanceAlignmentTasks,
      refetch: tasksRefetch,
    } = useTasks(
      computed(() => ({
        businessId: isAdmin.value && !reconciliationLoading.value && reconciliation.value?.businessId,
      }))
    );
    const { uncertainBillings } = useUncertainBillings(
      computed(() => ({ businessId: reconciliation.value?.businessId }))
    );

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    const { reconciliations } = useReconciliationsSummary(
      computed(() => ({
        businessId: !reconciliationLoading.value && reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
        fromPeriodDate: dateRange.value.fromDate
          ? DateTime.fromJSDate(dateRange.value.fromDate).minus({ month: 5 }).toISODate()
          : null,
        toPeriodDate: dateRange.value.toDate
          ? DateTime.fromJSDate(dateRange.value.toDate).plus({ month: 5 }).toISODate()
          : null,
      }))
    );

    const {
      reconciliationTemplate,
      loading: reconciliationTemplateLoading,
      refetch: reconciliationTemplateRefetch,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: reconciliation.value?.businessId,
        supplierId: reconciliation.value?.supplierId,
      }))
    );

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();

    const refetchData = async () => {
      await sleep(4000);
      await Promise.all([reconciliationRefetch(), balancePaymentsRefetch(), reconciliationTemplateRefetch()]);
    };

    const { mutate: closeReconciliation, onDone } = useCloseReconciliation();
    onDone(() => emit('reconciliation-closed'));

    const sumAmounts = (amounts) => {
      return amounts.reduce((acc, { amount }) => acc + amount, 0);
    };

    const { mutate: updateReconciliation } = useUpdateReconciliation();

    const { channelUnreadMessagesCount } = useChannel();

    const billedAmounts = computed(() =>
      (reconciliation.value?.billings ?? []).map((billing) => ({
        billingId: billing.id,
        amount: Number((billing.totalAmount / 100).toFixed(2)),
        date: billing.date,
      }))
    );

    const paidAmounts = computed(() => {
      const billingIdToAmount = billedAmounts.value.reduce((acc, { billingId, amount }) => {
        acc[billingId] = amount;
        return acc;
      }, {});

      return (reconciliation.value?.payments ?? []).reduce((acc, { billingLinks, id }) => {
        billingLinks.forEach((billingLink) => {
          if (billingLink.billingId in billingIdToAmount) {
            acc.push({
              paymentId: id,
              billingId: billingLink.billingId,
              amount: billingLink.amount,
            });
          }
        });
        return acc;
      }, []);
    });

    const { formatCentsToCurrency } = useCurrency();

    const formatMoneyAbs = (value) => {
      return formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    };

    const formatMoney = (value) => {
      return formatCentsToCurrency(value) ?? '-';
    };

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    const { onDone: onPaymentDeleteDone } = usePaymentDelete();
    onPaymentDeleteDone(() => {
      refetchData();
    });

    return {
      channelUnreadMessagesCount,
      reconciliationTasks: computed(() => [
        ...(handleReconciliationTasks.value ?? []).filter(
          ({ data: { reconciliationId } }) => reconciliationId === reconciliation.value?.id
        ),
        ...(supplierCreationTasks.value ?? []).filter(
          ({ data: { supplierId } }) => supplierId === reconciliation.value?.supplierId
        ),
        ...(uncertainBillingTasks.value ?? []).filter(({ data: { supplierId, uncertainBillingId } }) => {
          const uncertainBilling = (uncertainBillings.value ?? []).find(({ id }) => id === uncertainBillingId);
          const fromDate = reconciliation.value
            ? DateTime.fromISO(reconciliation.value.periodStart).minus({ months: 1 }).startOf('month').toISODate()
            : null;
          const toDate = reconciliation.value
            ? DateTime.fromISO(reconciliation.value.periodEnd).plus({ months: 1 }).endOf('month').toISODate()
            : null;
          return (
            supplierId === reconciliation.value?.supplierId &&
            fromDate <= uncertainBilling?.date &&
            toDate >= uncertainBilling?.date
          );
        }),
        ...(balanceAlignmentTasks.value ?? []).filter(({ data: { reconciliationIds } }) =>
          reconciliationIds.includes(reconciliation.value?.id)
        ),
      ]),
      patchTask,
      activeTask,
      tasksRefetch,
      BILLING_TYPE,
      supplier,
      selectedReconciliationId,
      reconciliation: computed(() => ({
        ...reconciliation.value,
        billings: (() => {
          const allBillings = uniqBy(prop('id'), reconciliation.value?.billings ?? []);
          const charges = allBillings.filter(
            ({ totalAmount, date }) =>
              totalAmount >= 0 && new Date(date) >= dateRange.value.fromDate && new Date(date) <= dateRange.value.toDate
          );
          const credits = allBillings
            .filter(({ totalAmount }) => totalAmount < 0)
            .map((credit) => {
              const currentReconciliationMatchedAmount = charges
                .filter((billing) => billing.related.some((link) => link.id === credit.id))
                .map((b) =>
                  pluck(
                    'netAmount',
                    b.related.filter((r) => r.id === credit.id)
                  )
                )
                .reduce((total, curr) => total + Math.abs(curr), 0);
              const otherReconciliationMatchedAmount = getOtherReconciliationMatchedAmount(credit, orderIds.value);
              return {
                ...credit,
                currentReconciliationMatchedAmount,
                otherReconciliationMatchedAmount,
                unmatchedAmount:
                  Math.abs(credit.netAmount) - currentReconciliationMatchedAmount - otherReconciliationMatchedAmount,
                imbalances: [],
                deliveryRefs: [],
                related: [],
              };
            });

          const sortDate = (a, b) => new Date(a.date) - new Date(b.date);

          const uncertainBillings =
            reconciliation.value?.uncertainBillings.map((uncertainBilling) => ({
              ...uncertainBilling,
              type: BILLING_TYPE.UNCERTAIN_BILLING,
            })) ?? [];

          return [
            unbilledOrdersGroup.value,
            ...[...charges, ...missingBillings.value, ...credits, ...uncertainBillings].sort(sortDate),
          ]
            .filter((b) => b)
            .map((billing) => {
              const imbalanceAmount =
                billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? getBillingImbalanceAmount(billing.imbalances) : null;
              const matchedAmount = billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? getMatchedAmount(billing) : null;
              const orderLinks = billing.orderLinks
                ?.map(({ order }) => {
                  const relevantOrder = reconciliation.value?.orders.find(({ id }) => id === order?.id);
                  return {
                    order: relevantOrder ? setDocumentsFromEventReferences(relevantOrder) : null,
                  };
                })
                .filter(({ order }) => order);
              const deliveryRefs = billing.deliveryRefs
                ?.map(({ delivery }) => ({
                  delivery: reconciliation.value?.deliveries.find(({ id }) => id === delivery?.id),
                }))
                .filter(({ delivery }) => delivery);

              const billingPaymentStatus = pipe(
                reject(isNil),
                head
              )(
                billedAmounts.value.map(({ billingId, amount: billedAmount }) => {
                  if (billingId !== billing.id) return;

                  const paymentStatus = paidAmounts.value.reduce(
                    (paidAmounts, { paymentId, billingId: paidBillingId, amount: paidAmount }) => {
                      if (paidBillingId === billingId) {
                        paidAmounts.push({
                          paymentId,
                          amount: paidAmount,
                          completed:
                            reconciliation.value.payments.find(({ id }) => id === paymentId)?.completed ?? false,
                        });
                      }
                      return paidAmounts;
                    },
                    []
                  );

                  const paymentIds = reject(
                    isNil,
                    paymentStatus.map(({ paymentId }) => paymentId)
                  );

                  const paidAmount = paymentStatus
                    .reduce(
                      (paidAmount, { amount, completed }) => (completed ? paidAmount.plus(amount) : paidAmount),
                      new Big(0)
                    )
                    .toNumber();
                  const completed = all(equals(true))(paymentStatus.map(({ completed }) => completed));
                  const leftToPay = billedAmount - paidAmount;

                  const resolveStatus = () => {
                    const baseStatus = {
                      // first payment is picked, since multiple payments are not supported by 'EventMapModal'
                      paymentId: paymentIds?.[0],
                      paidAmount: Number(((paidAmount ?? 0) * 100).toFixed(2)),
                    };

                    if (!paymentIds.length || !billedAmount) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.NOT_PAYED };
                    } else if (leftToPay === 0 && completed) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.FULLY_PAYED };
                    } else if (!completed) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.PAYMENT_IN_PROGRESS };
                    } else {
                      return {
                        ...baseStatus,
                        paymentState: BILLING_PAYMENT_STATE.PARTIALLY_PAYED,
                      };
                    }
                  };

                  return resolveStatus();
                })
              );

              return {
                ...billing,
                orderLinks,
                deliveryRefs,
                differences:
                  billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? transformDifferences(orderLinks, billing) : null,
                isMissingDocumentation:
                  billing.type !== BILLING_TYPE.UNCERTAIN_BILLING &&
                  (deliveryTasks.value.some(
                    ({ delivery }) =>
                      delivery.document.id === billing.source?.document?.id ||
                      orderLinks.some(({ order }) => delivery.document.id === order.source?.document?.id)
                  ) ||
                    orderLinks.some(({ order }) => isNil(order.netAmount)) ||
                    (billing.type === BILLING_TYPE.UNBILLED_ORDER && !isEmpty(billing.orderDifferences))),
                missingDeliveryNotes: missingEvents.value.filter(
                  ({ parentDocumentId }) => parentDocumentId === billing.source?.document?.id
                ),
                status: reconciliationLoading.value
                  ? RECONCILIATION_STATUSES.LOADING
                  : getBillingStatus(billing, reconciliation.value?.status),
                imbalanceAmount,
                matchedAmount,
                initialImbalance: imbalanceAmount + matchedAmount,
                paymentStatus: billingPaymentStatus,
              };
            });
        })(),
        billedAmounts: billedAmounts.value,
        paidAmounts: paidAmounts.value,
        totalBilledAmount: billedAmounts.value.length && Number(sumAmounts(billedAmounts.value).toFixed(2)),
        totalPaidAmount: paidAmounts.value.length && Number(sumAmounts(paidAmounts.value).toFixed(2)),
      })),
      dateRange,
      isDaily,
      balancePayment: computed(() => balancePayments.value[0]),
      credits,
      customerPaymentDifferences,
      orderDifferences,
      deliveryTasks: computed(() =>
        deliveryTasks.value.filter(({ delivery }) => delivery.document.supplierId === reconciliation.value?.supplierId)
      ),
      reconciliationDocuments,
      reconciliationDocumentsLoading,
      unbilledOrdersGroup,
      activity: ref(null),
      creditMatchModalData: ref(null),
      displayedDocumentId: ref(null),
      openPaymentDifferencesMatchingModal: ref(false),
      reconciliationLoading,
      hasReconciliationManage,
      reconciliationRefetch,
      eventDifferencesRefetch,
      refetchMissingEvents,
      patchMissingEvent,
      RECONCILIATION_STATUSES,
      RECONCILIATION_TAG_COLOR,
      RECONCILIATION_STATUS_COLOR,
      DOCUMENT_STATUSES,
      DOCUMENT_STATUS_COLOR,
      BILLING_PAYMENT_STATE,
      INNER_TABLE_ACTIONS,
      contactPopover: ref(false),
      contactUser: ref(null),
      token,
      isAdmin,
      ACTIONS,
      reconciliationTemplate,
      actionsLoading: computed(() => reconciliationLoading.value || reconciliationTemplateLoading.value),
      isSupplierTemplateDefined: computed(() => !!reconciliationTemplate.value?.supplierId),
      reconciliationTemplateParams: ref(null),
      createReconciliationTemplate,
      updateReconciliationTemplate,
      reconciliationTemplateRefetch,
      issueModalData: ref(null),
      balancePaymentModalSupplier: ref(null),
      refetchData,
      closeReconciliation,
      updateReconciliation,
      reconciliations,
      refetchLoading: ref(false),
      getHeight,
      formatMoneyAbs,
      formatMoney,
      balanceAlignmentReconciliation: ref(null),
    };
  },
  computed: {
    statusOverride() {
      return this.reconciliation.statusOverride;
    },
    mainStatus() {
      return this.reconciliation.status ? this.reconciliation.status?.status : RECONCILIATION_STATUSES.LOADING;
    },
    periodComplete() {
      return this.reconciliation.status
        ? this.reconciliation.status?.details.find(({ key }) => key === 'periodComplete').status
        : RECONCILIATION_STATUSES.LOADING;
    },
    snapshotStatus() {
      return this.reconciliation.status
        ? this.reconciliation.status?.details.find(({ key }) => key === 'snapshot').status
        : RECONCILIATION_STATUSES.LOADING;
    },
    balanceAlignmentStatus() {
      return this.reconciliation.status
        ? this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'balanceAlignment').status
        : RECONCILIATION_STATUSES.LOADING;
    },
    paymentDifferenceStatus() {
      return this.reconciliation.status
        ? this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'paymentDifference').status
        : RECONCILIATION_STATUSES.LOADING;
    },
    creditNotesStatus() {
      return this.reconciliation.status
        ? this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'creditNotes').status
        : RECONCILIATION_STATUSES.LOADING;
    },
    billingVerificationStatus() {
      const uncertainBillingDetails = this.reconciliation.status?.details.find(
        ({ key }) => key === 'uncertainBillings'
      );
      const billingDetails = this.reconciliation.status?.details.find(({ key }) => key === 'billings');

      if (this.reconciliationLoading) {
        return RECONCILIATION_STATUSES.LOADING;
      } else if (uncertainBillingDetails) {
        const { status, details } = uncertainBillingDetails;
        if (details.length && status !== RECONCILIATION_STATUSES.APPROVED) {
          return uncertainBillingDetails.status;
        }
      }

      return billingDetails?.status;
    },
    creditNotesToMatch() {
      const creditNotes = this.credits.filter(({ netAmount }) => netAmount < 0).map(createCreditRow);
      return creditNotes
        .filter(({ isFullyMatched }) => !isFullyMatched)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    problemCount() {
      let count = 0;
      if (this.balanceAlignmentStatus === this.mainStatus) count++;
      if (this.paymentDifferenceStatus === this.mainStatus) count++;
      if (this.creditNotesStatus === this.mainStatus) count++;

      const billingsStatus = this.reconciliation.billings.filter(
        ({ status: billingStatus }) => billingStatus === this.mainStatus
      );

      return count + billingsStatus.length ?? '';
    },
    reconciliationMonth() {
      return DateTime.fromJSDate(this.dateRange.fromDate).toFormat('yyyy-LL');
    },
    deliveryTasksIds() {
      return this.deliveryTasks.map(({ delivery }) => delivery.document.id);
    },
    isTaskCloseable() {
      return this.reconciliation.statusOverride
        ? this.reconciliation.statusOverride.status === RECONCILIATION_STATUSES.APPROVED
        : this.reconciliation.status === RECONCILIATION_STATUSES.APPROVED;
    },
    reconciliationOptions() {
      const beforeCurrent = this.reconciliations
        .filter(({ periodStart }) => this.reconciliation.periodStart > periodStart)
        .slice(0, 5);
      const afterCurrent = this.reconciliations
        .filter(({ periodStart }) => this.reconciliation.periodStart <= periodStart)
        .slice(0, 6);
      const filteredReconciliations = [...beforeCurrent, ...afterCurrent];
      return filteredReconciliations.map(({ id, periodStart, periodEnd }) => {
        const dateOptions = {
          timeZone: 'UTC',
          ...(periodStart === periodEnd ? options.twoDigits : { month: 'long', year: 'numeric' }),
        };
        return {
          id,
          label: new Date(periodStart).toLocaleDateString(this.$i18n.locale, dateOptions),
          disabled: id === this.reconciliation.id,
        };
      });
    },
    supplierPayments() {
      return pluck('supplierPayment', this.customerPaymentDifferences);
    },
    balanceAlignmentCardId() {
      return this.getCardId('balanceAlignment');
    },
    paymentDifferencesCardId() {
      return this.getCardId('paymentDifferences');
    },
    creditNotesToMatchCardId() {
      return this.getCardId('creditNotesToMatch');
    },
    statusReflection() {
      return this.reconciliation.statusReflection;
    },
  },
  updated() {
    document.querySelectorAll('.collapse').forEach((item) => {
      item.addEventListener('show.bs.collapse', ({ target: { id } }) => {
        document.getElementById(`card-${id}`)?.classList.add('card-shadow', 'card-expended-border');
        document.querySelector(`#card-${id} .header`)?.classList.add('card-extended-header');
        document.querySelector(`#card-${id} .header button`)?.classList.add('text-typography-primary');
        document.querySelector(`#card-${id} .header .total-amount`)?.classList.add('border-bottom-dashed-primary');
      });
      item.addEventListener('hide.bs.collapse', ({ target: { id } }) => {
        document.getElementById(`card-${id}`)?.classList.remove('card-shadow', 'card-expended-border');
        document.querySelector(`#card-${id} .header`)?.classList.remove('card-extended-header');
        document.querySelector(`#card-${id} .header button`)?.classList.remove('text-typography-primary');
        document.querySelector(`#card-${id} .header .total-amount`)?.classList.remove('border-bottom-dashed-primary');
      });
    });
  },
  methods: {
    handleReconciliationSelect(reconciliationId) {
      this.selectedReconciliationId = reconciliationId;
    },
    async handleUnbilledOrdersTableRefetch() {
      const height = document.querySelector('.modal-body-content').scrollHeight;
      document.querySelector('.reconciliation-modal .el-loading-mask').style.height = `${height}px`;

      this.refetchLoading = true;
      try {
        this.eventDifferencesRefetch();
        await sleep(3000);
        await this.reconciliationRefetch();
      } finally {
        this.refetchLoading = false;
      }
    },
    async handleBillingTableRefetch() {
      this.refetchLoading = true;
      await Promise.all([this.reconciliationRefetch(), this.refetchMissingEvents()]);
      this.refetchLoading = false;
    },
    handlePaymentLinkClicked(id) {
      this.activity = { type: 'payment', id };
      this.reconciliationRefetch();
    },
    async handleEventMapModalClose() {
      this.refetchLoading = true;
      this.activity = null;
      await this.reconciliationRefetch();
      this.refetchLoading = false;
    },
    transformDocStatusToReconciliationStatus(status) {
      if (status === DOCUMENT_STATUSES.CHECKED) return RECONCILIATION_STATUSES.APPROVED;
      else return RECONCILIATION_STATUSES.PENDING;
    },
    getReconciliationStatus(statuses) {
      if (statuses.some((s) => s === RECONCILIATION_STATUSES.LOADING)) return RECONCILIATION_STATUSES.LOADING;
      if (statuses.some((s) => s === RECONCILIATION_STATUSES.NOT_APPROVED)) return RECONCILIATION_STATUSES.NOT_APPROVED;
      if (statuses.some((s) => s === RECONCILIATION_STATUSES.PENDING)) return RECONCILIATION_STATUSES.PENDING;
      if (statuses.every((s) => s === RECONCILIATION_STATUSES.APPROVED)) return RECONCILIATION_STATUSES.APPROVED;
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      const dateOptions = {
        timeZone: 'UTC',
        ...(isDaily ? options.twoDigits : { month: 'short', year: 'numeric' }),
      };
      return new Date(date).toLocaleDateString(this.$i18n.locale, dateOptions);
    },
    handleOpenMatchCredits(data) {
      this.creditMatchModalData = data;
    },
    handleOpenEventMap(data) {
      this.activity = data;
    },
    handleOpenDocument(documentId) {
      this.displayedDocumentId = documentId;
    },
    async handlePaymentsMatchSuccess() {
      this.openPaymentDifferencesMatchingModal = false;
      await this.eventDifferencesRefetch();
      if (!this.customerPaymentDifferences.length) {
        document
          .querySelector(`div[data-bs-target='#${this.paymentDifferencesCardId}']`)
          .setAttribute('aria-expanded', 'false');
        document
          .getElementById(`card-${this.paymentDifferencesCardId}`)
          .classList.remove('card-shadow', 'card-expended-border');
        document
          .querySelector(`#card-${this.paymentDifferencesCardId} .header`)
          .classList.remove('card-extended-header');
      }
    },
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    onClickOutside() {
      this.contactPopover = false;
    },
    async handleAction(command) {
      const { id, billedAmounts, unbilledOrders, periodStart } = this.reconciliation;
      switch (command) {
        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            supplierId: this.supplier.id,
            month: DateTime.fromJSDate(formatBilledDate([...billedAmounts, ...unbilledOrders], true)).toFormat(
              'yyyy-LL'
            ),
          };
          break;
        case ACTIONS.OPEN_CREDIT_MATCH_MODAL:
          this.creditMatchModalData = {
            supplier: this.supplier,
            month: DateTime.fromJSDate(formatBilledDate([...billedAmounts, ...unbilledOrders], true)).toFormat(
              'yyyy-LL'
            ),
          };
          break;
        case ACTIONS.OPEN_RECONCILIATION_SETTINGS:
          this.reconciliationTemplateParams = {
            supplierId: this.supplier.id,
            template: this.reconciliationTemplate,
          };
          break;
        case ACTIONS.BALANCE_UPDATE:
          this.balancePaymentModalSupplier = this.supplier;
          break;
        case ACTIONS.VALIDATE_BALANCE_ALIGNMENT:
          this.balanceAlignmentReconciliation = this.reconciliation;
          break;
        case ACTIONS.CLOSE_RECONCILIATION:
          this.handleCloseReconciliation(id, periodStart, this.isDaily);
          break;
        case ACTIONS.OPEN_CHAT:
          this.handleChatOpen();
          break;
        default:
          break;
      }
    },
    handleChatOpen() {
      this.$emit('open-chat', {
        supplier: this.supplier,
        reconciliationId: this.selectedReconciliationId,
        reconciliationPeriod: this.reconciliation.periodStart,
        reconciliationClosed: this.reconciliation.closed,
        isDaily: this.reconciliation.periodStart === this.reconciliation.periodEnd,
      });
    },
    async validateBalanceAlignment({ reconciliationId, amount, note }) {
      try {
        this.balanceAlignmentReconciliation = null;
        await this.updateReconciliation({
          id: reconciliationId,
          patchParams: { balanceAlignment: reject(isNil, { validated: true, amount, note }) },
        });
        this.$message.success(this.$t('commons.messages.action.success'));
        await sleep(4000);
        await this.reconciliationRefetch();
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('commons.messages.action.error'));
      }
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    async handleCloseReconciliation(id, period, isDaily) {
      this.$confirm(
        this.$t('payment.paymentTable.reconciliationCloseConfirm.text', {
          date: this.formatDateShort(period, isDaily),
          client: this.supplier.name,
        }),
        this.$t('payment.paymentTable.reconciliationCloseConfirm.title'),
        {
          showClose: false,
          confirmButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.confirm'),
          cancelButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.cancel'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(async () => {
          try {
            await this.closeReconciliation({ id });
            this.$message.success(this.$t('payment.paymentTable.messages.reconciliationClosedSuccessfully'));
          } catch (error) {
            console.error(error);
            this.$message.error(this.$t('payment.paymentTable.messages.reconciliationClosingFailed'));
          }
        })
        .catch(() => null);
    },
    getCardId(cardName) {
      return `${cardName}-${Math.floor(Math.random() * 1000000)}`;
    },
    async handleTaskUpdate(updateData) {
      await this.patchTask({
        taskId: updateData.task.id,
        patchParams: {
          data: { ...updateData.task.data, comment: updateData.comment },
        },
      });
      if (!isNil(updateData.activeAt)) {
        await this.activeTask({
          taskId: updateData.task.id,
          activeParams: {
            activeAt: updateData.activeAt,
          },
        });
      }
      this.tasksRefetch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
$gray: #f3f3f4;
$darken-gray: #e3e4e6;

.el-dropdown-link {
  display: flex;
  align-items: center;

  .reconciliation-title {
    color: $typography-primary;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.reconciliation-select {
  width: 240px;
}

::v-deep .reconciliation-modal {
  $total-height: 95vh;
  $header-height: 86px;
  $body-height: calc($total-height - $header-height);
  height: $total-height;
  width: 90%;
  max-width: 1216px;

  .el-dialog__header {
    height: $header-height;
  }
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
    color: $typography-primary;
  }

  .el-dialog__body {
    height: $body-height;
  }
}

.action-btn {
  height: fit-content;
  &:hover,
  &:active {
    background: $secondary;
  }
}

.reconciliation-modal .card .header {
  cursor: pointer;
  height: 52px;
  color: #46494f;
  &:hover:not(svg):not(.credit-circle) {
    background: $gray;
    color: $typography-primary;
    border-radius: 6px;
  }
}

.card .header:hover button {
  color: $typography-primary;
}

.total-amount {
  border-bottom: 1px dashed #94989f;
}

.card .header:hover .total-amount {
  border-bottom: 1px dashed $typography-primary;
}

div[aria-expanded='true'] {
  border-bottom: 1px solid #e5e8ea;
}

.stepper {
  border-left: 1px solid #e5e8ea;
  margin-top: 4px;
  margin-bottom: 36px;
}

.disabled {
  color: #94989f !important;
}

.tooltip {
  width: 300px;
}

.approved {
  color: $success;
}

.main-status-circle {
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 500;
}

.before-payment-due-circle {
  background-color: #ecf0f3;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 500;
}

.credit-circle {
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 9px;
  background: $primary;
  margin: 0px 8px;
  align-self: center;
}

::v-deep .el-popover {
  padding: 0 !important;
  right: 60px !important;
  top: 69px !important;
}

.p-05 {
  padding: 0.1225rem !important;
}

.on-the-line {
  left: 4px;
}

.modal-body-content {
  border-radius: 0px 0px 6px 6px;
}

.border-bottom-dashed-primary {
  border-bottom: 1px dashed $typography-primary;
}

.border-dashed {
  border: 1px dashed $darken-gray;
}

.card-shadow {
  box-shadow: 0 0 4px rgba(19, 26, 36, 0.2);
}

.card-expended-border {
  border: 1px solid #d2d4d7;
}

.card-extended-header {
  background: $gray;
  color: $typography-primary !important;
  border-radius: 6px 6px 0px 0px !important;
  &:hover:not(svg):not(.credit-circle) {
    background: $darken-gray !important;
    border-radius: 6px 6px 0px 0px !important;
  }
}

.cards-alignment {
  min-width: 496px;
  max-width: 652px;
  flex: 1;
}
</style>
